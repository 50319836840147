import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import Section from '../../components/Layout/Section'
import Banner from '../../components/Banner'
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumbs'
import { Container, Form, Row, Col } from 'react-bootstrap'
import Title from '../../components/Layout/Title'
import Select from 'react-select'
import Icon from '../../images/icons.svg'
import DefaultImage from '../../images/threeimg-1.png'
import ReactPaginate from 'react-paginate'
import { Helmet } from 'react-helmet'

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: base => ({
    ...base,
    width: 'max-content',
    zIndex: 10,
  }),
  container: provided => ({
    ...provided,
    minWidth: 125,
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? 'rgba(0, 0, 0, 0.5)'
      : isSelected
      ? 'rgba(0, 0, 0, 1)'
      : 'rgba(0, 0, 0, 0)',
    color: isFocused ? '#ffffff' : isSelected ? '#ffffff' : undefined,
  }),
}

const options = [
  { value: 'all_month', label: 'All Months' },
  { value: '1_month', label: '1 Month' },
  { value: '2_month', label: '2 Months' },
]

const NewsCategoryPage = ({ pageContext: { page, nodes } }) => {
  const breadcrumbs_pages = [page]
  const categories = page.categories.nodes
  var newsArticles = page.children.nodes
  var itemsPerPage = 4

  const search = typeof window !== 'undefined' ? window.location.search : '/'
  const params = new URLSearchParams(search)
  var category_param = params.get('category')

  const [currentItems, setCurrentItems] = useState(null)
  const [totalItems, setTotalItems] = useState(newsArticles.length)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [itemEndOffset, setItemEndOffset] = useState(itemsPerPage)
  useEffect(() => {
    if (category_param) {
      newsArticles = newsArticles.filter(news => {
        let newsCategories = news.categories.nodes
        var isValid = false
        newsCategories.some(function (newsCategory) {
          if (newsCategory.urlSegment === category_param) {
            isValid = true
            return
          }
        })
        return isValid
      })
    }
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(newsArticles.slice(itemOffset, endOffset))
    setTotalItems(newsArticles.length)
    setPageCount(Math.ceil(newsArticles.length / itemsPerPage))
  }, [itemOffset, itemsPerPage])
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % totalItems
    setItemOffset(newOffset)
    let endOffset =
      newOffset + itemsPerPage > totalItems
        ? totalItems
        : newOffset + itemsPerPage
    setItemEndOffset(endOffset)
  }
  const hasBanner = page.bannerImage && page.bannerImage.absoluteLink
  return (
    <Layout>
      <Helmet>
             <title>{ page.title } | Grocorp Furniture</title>
        </Helmet>
      {hasBanner && (
        <Banner title={page.title} imageURL={page.bannerImage.absoluteLink} />
      )}
      <Breadcrumbs pages={breadcrumbs_pages} />
      <Section noSpacingTop>
        <Container>
          <Title
            subTitle={page.subTitle}
            title={page.headTitle}
            className="text-center"
          />
          {categories.length > 0 && (
            <div className="blog-button-group">
              <a
                href={page.link}
                className={`btn btn-outline-primary${
                  category_param ? '' : ' active'
                }`}
              >
                All
              </a>
              {categories.map((category, idx) => {
                var isActive = category.urlSegment === category_param
                return (
                  <a
                    key={`category-${category.id}`}
                    data-id={category.id}
                    href={`${page.link}?category=${category.urlSegment}`}
                    className={`btn btn-outline-primary${
                      isActive ? ' active' : ''
                    }`}
                  >
                    {category.name}
                  </a>
                )
              })}
            </div>
          )}
          <div className="filter mt-1">
            <Container className="px-0">
              <Row className="justify-content-center">
                <Col xs={12} md={7} lg={6} xl={5}>
                  <div className="filter-search">
                    <Form>
                      <Form.Group className="form-group" controlId="BLogSearch">
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Search"
                        />
                      </Form.Group>
                      <button className="btn-reset search-submit">
                        <svg width="20" height="20">
                          <use xlinkHref={`${Icon}#icon-search`}></use>
                        </svg>
                      </button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>

            {/* <div className="filter-select">
                <p className="h5">Filter by Month</p>
                <Select
                  defaultValue={{ value: "all_month", label: "All Months" }}
                  isSearchable={false}
                  styles={customStyles}
                  options={options}
                />
              </div> */}
          </div>
          {currentItems && currentItems.length > 0 && (
            <div className="blog-list">
              <Row>
                {currentItems.map((article, idx) => {
                  let articleCategories = article.categories.nodes
                  return (
                    <Col key={`newsarticle-${article.id}`} xs={6} lg={12}>
                      <div className="blog-item">
                        <Row>
                          <Col xs={12} lg={5} xl={4}>
                            <div className="blog-image">
                              <img
                                src={
                                  article.featuredImage.absoluteLink
                                    ? article.featuredImage.absoluteLink
                                    : DefaultImage
                                }
                              />
                            </div>
                          </Col>
                          <Col xs={12} lg={7} xl={8}>
                            <div className="blog-desc">
                              <div className="top">
                                <div className="span-group">
                                  {articleCategories.length > 0 && (
                                    <span className="text-sm">
                                      {articleCategories.map(
                                        (category, idx) => {
                                          let isLast =
                                            idx === articleCategories.length - 1
                                          return (
                                            <span
                                              key={`category-${category.id}`}
                                            >
                                              {category.name}
                                              {isLast ? '' : ', '}
                                            </span>
                                          )
                                        }
                                      )}
                                    </span>
                                  )}
                                  <span className="text-sm">
                                    {article.publishedDate}
                                  </span>
                                </div>
                                <a href={article.link} className="h3">
                                  {article.title}
                                </a>
                                <p>{article.summary}</p>
                              </div>
                              <div className="bot">
                                <a href={article.link} className="cta">
                                  Read More
                                </a>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          )}
          <div className="pagination-wrap">
            <ReactPaginate
              breakLabel="..."
              nextLabel="›"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="‹"
              renderOnZeroPageCount={null}
              containerClassName="justify-content-center pagination"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              activeClassName="page-item active"
            />
            <p className="text-sm text-secondary">
              Show {itemOffset ? itemOffset : 1} to {itemEndOffset} of{' '}
              {newsArticles.length}
            </p>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default NewsCategoryPage
